
.card-background {
  background-color: #323232;
}

article {
  width: 90%;
  margin: 0 auto ;
}
.title-xl {
  font-size: 7vw!important;
}
.title-l {
  font-size: 2vw!important;
  font-weight: bold;
}
 
.items-container:nth-child(2) {
  display: none!important;
}

nav, .appbar:nth-child(0) {
  display: none!important;
}
.left {
  position: absolute;
  top: 50%;
  left: 5%;
  transform: translate(0%, -50%);
}

.ImageTitel {
    position: relative;
    color: white;
}

.ImageTitel img {
  width: 100%;
}

body {
  /*background-color: #2A2A2A*/
  overflow: auto!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
